import type {  CustomSummaryAggregate, ServerSummaryAggregate, default as SummaryData } from 'o365.modules.DataObject.extensions.SummaryData.ts';

import { DataGridControl } from 'o365.controls.DataGrid.ts';
import { watch, computed } from 'vue';

// import logger from 'o365.modules.Logger.ts';

declare module 'o365.controls.DataGrid.ts' {
    interface DataGridControl {
        summaryData: DataGridSummaryData;
        hasSummaryData: boolean;
    }
}

Object.defineProperties(DataGridControl.prototype, {
    'summaryData': {
        get() {
            if (this._summaryData == null) {
                this._summaryData = new DataGridSummaryData(this);
                this._summaryData.initialize();
            }
            return this._summaryData;
        }
    },
    'hasSummaryData': {
        get() {
            return !!this._summaryData;
        }
    }
});

export default class DataGridSummaryData {
    private _dataGridControl: DataGridControl;
    private _iniitialized = false;

    private _summary: Record<string,any> = {};
    private _aggregates: SummaryData['_aggregates'] = [];

    private _cleanupTokens: (() => void)[] = [];
    private _isLoading = false;

    /** When true will skip reload checks based on request keys */
    private _skipLoadChecks = false;

    get skipLoadChecks() {
        if (this._dataGridControl.dataObject) {
            return this._dataGridControl.dataObject.summaryData.skipLoadChecks;
        } else {
            return this._skipLoadChecks;
        }
    }
    set skipLoadChecks(pValue) {
        if (this._dataGridControl.dataObject) {
            this._dataGridControl.dataObject.summaryData.skipLoadChecks = pValue;
        } else {
            this._skipLoadChecks = pValue;
        }
    }


    get summary() {
        if (this.useDataObject) {
            return this._dataGridControl.dataObject!.summaryData.summary;
        } else {
            return this._summary;
        }
    }

    get aggregates() {
        if (this.useDataObject) {
            return this._dataGridControl.dataObject!.summaryData.aggregates;
        } else {
            return this._aggregates;
        }
    }

    get isLoading() {
        if (this.useDataObject) {
            return this._dataGridControl.dataObject!.summaryData.isLoading;
        } else {
            return this._isLoading;
        }
    }

    get useDataObject() {
        return this._dataGridControl.dataObject;
    }

    constructor (pDataGridControl: DataGridControl) {
        this._dataGridControl = pDataGridControl;
    }

    initialize() {
        if (this._iniitialized) { return; }
        this._iniitialized = true;

        if (this.useDataObject) { return; }
        const sortedArray = computed(() => {
            return [...this._dataGridControl.utils?.processedData ?? []].sort();
        });
        this._cleanupTokens.push(watch(sortedArray, () => {
            console.log('trigger update')
            this.fetchAggregates();
        }))
    }

    setAggregates(...args: Parameters<SummaryData['setAggregates']>): ReturnType<SummaryData['setAggregates']> {
        if (this.useDataObject) {
            return this._dataGridControl.dataObject!.summaryData.setAggregates(...args);
        }

        const [ pAggregates ] = args;
         if (pAggregates == null) {
            this._aggregates = [];
        } else {
            this._aggregates = pAggregates;
        }
    }

    async fetchAggregates(...args: Parameters<SummaryData['fetchAggregates']>): ReturnType<SummaryData['fetchAggregates']> {
        if (this.useDataObject) {
            return this._dataGridControl.dataObject!.summaryData.fetchAggregates(...args);
        }

        if (this._aggregates.length === 0) {
            this._summary = {};
            return;
        }
        this._isLoading = true;

        const customAggregates: CustomSummaryAggregate[] = [];
        const serverAggregates: ServerSummaryAggregate[] = [];
        this._aggregates.forEach(aggregate => {
            if (typeof aggregate.aggregate === 'string') {
                serverAggregates.push(aggregate);
            } else {
                customAggregates.push(aggregate);
            }
        });
        let serverPromise: Promise<Record<string, any>> | null = null;
        let customPromise: Promise<Record<string, any>> | null = null;

        if (serverAggregates.length > 0) {
            serverPromise = this._serverAggregatePolyfill(serverAggregates);
        }

        if (customAggregates.length > 0) {
            customPromise = Promise.all(customAggregates.map(aggregate => {
                return new Promise<{ name: string, value: any }>(async (res) => {
                    const value = await aggregate.aggregate(this._dataGridControl.utils?.processedData ?? []);
                    res({ name: aggregate.name, value: value });
                });
            })).then(values => {
                const summaryItem: Record<string, any> = {};
                values.forEach(item => summaryItem[item.name] = item.value);
                return summaryItem;
            });
        }

        const promises: Promise<Record<string, any>>[] = [];
        if (serverPromise) { promises.push(serverPromise); }
        if (customPromise) { promises.push(customPromise); }
        const results = await Promise.all(promises);
        const result = results.reduce((result, item) => ({ ...result, ...item }), {});

        if (result) {
            this._summary = result;
        } else {
            this._summary = {};
        }
        this._isLoading = false;
    }

    private _serverAggregatePolyfill(pAggregates: ServerSummaryAggregate[]) {
        const result: Record<string, any> = {};
        if (this._dataGridControl.utils?.processedData) {
            pAggregates.forEach(aggregate => {
                switch (aggregate.aggregate) {
                    case 'SUM':
                        result[aggregate.name] = this._dataGridControl.utils.processedData!.reduce((sum, item) => {
                            if (sum == null) {
                                sum = item[aggregate.name];
                            } else {
                                sum += item[aggregate.name];
                            }
                            return sum;
                        }, undefined as any);
                        break;
                    case 'AVG':
                        const sum = this._dataGridControl.utils.processedData!.reduce((sum, item) => {
                            if (sum == null) {
                                sum = item[aggregate.name];
                            } else {
                                sum += item[aggregate.name];
                            }
                            return sum;
                        }, undefined as any);
                        result[aggregate.name] = sum / this._dataGridControl.utils.processedData!.length;
                        break;
                    case 'MAX':
                        result[aggregate.name] = this._dataGridControl.utils.processedData!.reduce((max, item) => {
                            if ((max == null || max > item[aggregate.name])) {
                                max = item[aggregate.name];
                            }
                            return max;
                        }, undefined as number|undefined);
                        break;
                    case 'MIN':
                        result[aggregate.name] = this._dataGridControl.utils.processedData!.reduce((min, item) => {
                            if ((min == null || min < item[aggregate.name])) {
                                min = item[aggregate.name];
                            }
                            return min;
                        }, undefined as number|undefined);
                        break;
                    case 'COUNT':
                        result[aggregate.name] = this._dataGridControl.utils.processedData!.reduce((count, item) => {
                            if (item[aggregate.name] != null) { count++; }
                            return count;
                        }, 0);
                        break;
                }
            });
        }

        return Promise.resolve(result);
    }
}
